// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Anchor,
  Box,
  Button,
  CheckBox,
  Main,
  Select, Table, TableBody, TableCell, TableHeader,
  TableRow,
} from 'grommet';
import {
  Add, Search, Trash,
} from 'grommet-icons';
import PropTypes from 'prop-types';
import SaveChangesDialog from '../shared/dialogs/SaveChangesDialog';
import IDUtil from '../shared/util/IDUtil';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import GLBMSearch from '../shared/component/GLBMSearch';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import ASMListEditor from './ASMListEditor';
import Loader from '../shared/loader';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import GLBMHeading from '../shared/component/GLBMHeading';
import { insertIf } from '../shared/util/BasicUtil';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import {
  useASMUsersQuery, useCustomerQuery, useRemoveASMMutate, useSaveASMMutate,
} from '../../core';
import UserStore from '../stores/UserStore';

/**
 * For all the users in adminList, if not found in users list, then remove from adminList, user must be deleted
 * @param users
 * @param adminList
 */
const pruneMissingUsers = (adminList, users) => {
  for (let i = adminList.length - 1; i >= 0; i -= 1) {
    const admin = adminList[i];
    if (!Object.hasOwn(users, admin.id)) {
      console.warn(`Removing admin user ${admin.id}${+', user not found in global user list'}`);
      adminList.splice(i, 1);
    }
  }
  return adminList;
};

const ASMListPage = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { hasPermissions } = usePermissionChecker();

  const {
    data: users,
  } = useASMUsersQuery({
    select: res => res.reduce((map, user) => {
      // eslint-disable-next-line no-param-reassign
      map[user.id] = user;
      return map;
    }, {}),
  });

  const {
    data: customer,
    isFetching: loadAdmins,
  } = useCustomerQuery(customerId);
  const asmList = useMemo(() => customer?.asmConfig?.asms, [customer]);

  const [adminList, setAdminList] = useState();
  const [originalAdminList, setOriginalAdminList] = useState();
  const [removedList, setRemovedList] = useState([]);
  const [sortProperties] = useState(['firstName', 'lastName', 'email', 'role']);
  const [sortIndex, setSortIndex] = useState(0);
  const [sortAscending, setSortAscending] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [layer, setLayer] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);

  const me = useMemo(() => UserStore.getUser(), []);

  const { mutate: saveASMs } = useSaveASMMutate(customerId);
  const { mutate: removeASMs } = useRemoveASMMutate(customerId);

  useEffect(() => {
    if (asmList && users) {
      const fetchedAdminList = pruneMissingUsers([...asmList], users);
      setAdminList(fetchedAdminList);
      setOriginalAdminList(JSON.parse(JSON.stringify(fetchedAdminList)));
    }
  }, [asmList, users]);

  const _onSortList = (index, ascending) => {
    if (index >= sortProperties.length) {
      return;
    }
    let updatedAdminList;
    const property = sortProperties[index];

    switch (property) {
      case 'role':
        updatedAdminList = adminList.sort((a, b) => {
          if (a.roles[0] === b.roles[0]) {
            return 0;
          } if (a.roles[0] < b.roles[0]) {
            return -1;
          } return 1;
        });
        break;
      default:
        updatedAdminList = adminList.sort((a, b) => {
          if (a[property] === b[property]) {
            return 0;
          } if (a[property] < b[property]) {
            return -1;
          } return 1;
        });
        break;
    }
    if (!ascending) {
      updatedAdminList.reverse();
    }
    setSortIndex(index);
    setSortAscending(ascending);
    setAdminList(updatedAdminList);
  };

  const onSearchChange = (props) => {
    setSearchText(props?.toLowerCase());
  };

  const filteredAdmins = useMemo(() => {
    const results = [];
    if (!searchText) {
      return adminList;
    }
    adminList.forEach((admin) => {
      const user = users[admin.id];
      if (user.email.toLowerCase().indexOf(searchText) >= 0
          || user.firstName.toLowerCase().indexOf(searchText) >= 0
          || user.lastName.toLowerCase().indexOf(searchText) >= 0) {
        results.push(admin);
      }
    });
    return results;
  }, [searchText, adminList]);

  const _onSubmitASMEdit = () => {
    const updatedCustomer = { ...customer };
    updatedCustomer.asmConfig.asms = adminList;
    saveASMs(updatedCustomer.asmConfig, {
      onSuccess: () => {
        setOriginalAdminList(adminList);
        setTimeout(() => {
          navigate('/customers');
        }, 100);
      },
    });
  };

  const _onSubmitASMRemove = () => {
    removeASMs(removedList, {
      onSuccess: () => {
        setOriginalAdminList(adminList);
        setTimeout(() => {
          navigate('/customers');
        }, 0);
      },
    });
  };

  const _onClickCancel = () => {
    navigate('/customers');
  };

  const _isDirty = useMemo(
    () => JSON.stringify(adminList) !== JSON.stringify(originalAdminList),
    [adminList, originalAdminList],
  );

  const _onRemoveClick = (selectedInd) => {
    setLayer('removeConfirm');
    setSelectedIndex(selectedInd);
  };

  const _layerClosed = () => {
    setLayer(undefined);
  };

  const _onAddAdminClicked = () => {
    setLayer('addAdmin');
  };

  const _onASMListEditorApplied = (admintrationList) => {
    setLayer(undefined);
    setAdminList(admintrationList);
  };

  const _onAdminListRemoveConfirmed = ({ index }) => {
    const updatedAdminList = [...adminList];
    updatedAdminList.splice(index, 1);
    setLayer(undefined);
    setAdminList(updatedAdminList);
  };

  const _onChangeRole = (admin, value) => {
    const updatedAdminList = [...adminList];
    updatedAdminList.forEach((a) => {
      if (a.id === admin.id) {
        // eslint-disable-next-line no-param-reassign
        a.roles[0] = value;
      }
    });
    setAdminList(updatedAdminList);
  };

  const _getRoleValue = (role) => {
    const roles = [
      { value: 'READ', label: 'Read' },
      { value: 'EDIT', label: 'Edit' },
      { value: 'SUPER_EDIT', label: 'Super Edit' },
    ];
    return roles.filter(r => r.value === role)[0] || { value: 'READ', label: 'Read' };
  };

  const _renderConfirmationDetails = (admin) => {
    const user = users[admin.id];
    return (
      <GLBMNameValueList
        title='Selected ASM'
        data={[
          { label: 'Name', value: `${user.firstName} ${user.lastName}` },
          { label: 'Email', value: user.email },
          { label: 'Role', value: _getRoleValue(admin.roles[0]).label },
        ]}
      />
    );
  };

  const _renderLayer = () => {
    let result;
    if (layer) {
      if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={{ index: selectedIndex }}
            title='Remove ASM?'
            submitLabel='Yes, remove the ASM'
            cancelLabel='Cancel'
            onClose={_layerClosed}
            onChange={_onAdminListRemoveConfirmed}
            details={_renderConfirmationDetails(adminList[selectedIndex])}
          />
        );
      } else if (layer === 'addAdmin') {
        const currentASMIds = [];
        adminList.forEach((admin) => {
          currentASMIds.push(admin.id);
        });

        result = (
          <ASMListEditor
            onClose={_layerClosed}
            onChange={_onASMListEditorApplied}
            adminList={adminList}
            users={users}
            currentASMIds={currentASMIds}
          />
        );
      }
    }
    return result;
  };

  const _noRowsElement = (totalAdmins, filteredAdminsUsers) => {
    if (loadAdmins) {
      return (
        <Box direction='row' align='center' gap='small' justify='center' fill={true}>
          <Loader text='Loading Users. Please wait ...' />
        </Box>
      );
    }
    if (totalAdmins === 0) {
      return (
        <ListPlaceholder
          emptyMessage='You have not added any ASMs to this Billing Account.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    } if (filteredAdminsUsers === 0) {
      return (
        <ListPlaceholder
          emptyMessage='Your filter returned zero ASMs, adjust to continue.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }
    return '';
  };

  const _onToggleRemovedSelected = (event, id) => {
    const updatedRemovedList = { ...removedList };
    if (event.target.checked) {
      updatedRemovedList.push(id);
    } else {
      const index = updatedRemovedList.indexOf(id);
      if (index !== -1) {
        updatedRemovedList.splice(index, 1);
      }
    }
    setRemovedList(updatedRemovedList);
  };

  const rows = [];
  const options = [
    { value: 'READ', label: 'Read' },
    { value: 'EDIT', label: 'Edit' },
    { value: 'SUPER_EDIT', label: 'Super Edit' },
  ];

  const myAsmRoles = customer && customer.asmConfig && customer.asmConfig.asms.find(el => el.id === me.id) ? customer.asmConfig.asms.find(el => el.id === me.id).roles : [];
  const canEdit = hasPermissions(pagePermissions.customers.view.admins.actions.edit);
  const canDelete = hasPermissions(pagePermissions.customers.view.admins.actions.remove) && ['EDIT', 'SUPER_EDIT'].includes(myAsmRoles[0]);

  if (filteredAdmins && users && Object.keys(users).length) {
    filteredAdmins.forEach((admin, index) => {
      if (Object.hasOwn(users, admin.id)) {
        rows.push(
          <TableRow key={admin.id}>
            {canDelete && (
            <TableCell>
              <CheckBox
                checked={removedList.includes(admin.id)}
                onChange={event => _onToggleRemovedSelected(event, admin.id)}
              />
            </TableCell>
            )}
            <TableCell>{users[admin.id].firstName}</TableCell>
            <TableCell>{users[admin.id].lastName}</TableCell>
            <TableCell>{users[admin.id].email}</TableCell>
            <TableCell>
              {canEdit
                ? (
                  <Select
                    id={IDUtil.getId('ASMListViewListRoleDropDown', index)}
                    name='select'
                    placeholder='Select'
                    labelKey='label'
                    valueKey={{ key: 'value', reduce: true }}
                    value={admin.roles[0] || ''}
                    options={options}
                    onChange={({ value: nextValue }) => _onChangeRole(admin, nextValue)}
                  />
                )
                : _getRoleValue(admin.roles[0]).label}
            </TableCell>
            {canEdit && (
              <TableCell>
                <Anchor
                  icon={<Trash />}
                  label='Remove'
                  id={IDUtil.getId('ASMListViewListRemoveButton', index)}
                  onClick={() => _onRemoveClick(index)}
                />
              </TableCell>
            )}
          </TableRow>,
        );
      } else {
        console.error('user not found', admin, users);
      }
    });
  }

  // if no rows:
  const noRows = _noRowsElement((adminList || []).length, (filteredAdmins || []).length);

  // eslint-disable-next-line no-nested-ternary
  const headers = (canEdit) ? ['First Name', 'Last Name', 'Email', 'Role', 'Action'] : (canDelete) ? ['Remove', 'First Name', 'Last Name', 'Email', 'Role'] : ['First Name', 'Last Name', 'Email', 'Role'];

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back='/customers'
        title={`ASMs for ${customer ? `${customer.name} (${customer.id})` : ''}`}
        search={(
          <GLBMSearch
            placeholder='Search'
            icon={<Search />}
            onChange={onSearchChange}
            id={IDUtil.getId('ASMListViewToolbarSearchInput')}
          />
)}
        actions={[
          ...insertIf(canEdit, [
            <Button
              kind='toolbar'
              id={IDUtil.getId('ASMListViewToolbarAddButton')}
              icon={<Add />}
              disabled={!users}
              onClick={() => _onAddAdminClicked()}
              key='addButton'
              label='Add'
            />]),
        ]}
      />
      <Box direction='column' fill='vertical'>
        <Box flex={true} pad={{ horizontal: 'medium' }}>
          <Box flex={false}>
            <Table>
              <TableHeader
                sortIndex={sortIndex}
                sortAscending={sortAscending}
                onSort={_onSortList}
              >
                <TableRow>
                  {headers.map(column => <TableCell scope='col' border='bottom' style={{ padding: '6px 12px' }}>{column}</TableCell>)}
                </TableRow>
              </TableHeader>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
            {noRows}
          </Box>
        </Box>
        <Box
          direction='row'
          pad={{ horizontal: 'small', vertical: 'small' }}
          gap='small'
          flex={false}
          border='top'
        >
          {canEdit && (
            <Button
              label='Save'
              type='button'
              primary={true}
              id={IDUtil.getId('EditorViewToolbarSaveButton')}
              onClick={_onSubmitASMEdit}
            />
          )}
          {canDelete && (
            <Button
              label={`Remove ${removedList.length} ASM${removedList.length === 1 ? '' : 's'}`}
              type='button'
              primary={true}
              id={IDUtil.getId('EditorViewToolbarDeleteButton')}
              onClick={removedList.length !== 0 ? _onSubmitASMRemove : undefined}
            />
          )}
          <Button
            label={canEdit || canDelete ? 'Cancel' : 'Close'}
            type='button'
            secondary={true}
            id={IDUtil.getId('EditorViewToolbarCancelButton')}
            onClick={_onClickCancel}
          />
        </Box>
        {_renderLayer()}
        <ReactRouterPrompt when={_isDirty}>
          {({ onConfirm, onCancel }) => (
            <SaveChangesDialog
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </ReactRouterPrompt>
      </Box>
    </Main>
  );
};

ASMListPage.contextTypes = {
  router: PropTypes.object,
};

ASMListPage.propTypes = {};

export default ASMListPage;
