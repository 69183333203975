// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePermissionChecker } from '../shared/hooks/permissions';
import { pagePermissions } from '../shared/constants/Permissions';

import CompanyEditorPage from './CompanyEditorPage';
import CompanyListPage from './CompanyListPage';

const CompanyPage = () => {
  const { hasPermissions } = usePermissionChecker();
  return (
    <Routes>
      <Route key='listRoute' path='/' element={<CompanyListPage />} />
      { hasPermissions(pagePermissions.companies.add) && <Route key='addRoute' path='/add' element={<CompanyEditorPage />} /> }
      { hasPermissions(pagePermissions.companies.edit) && <Route key='editRoute' path='/:companyId' element={<CompanyEditorPage />} /> }
    </Routes>
  );
};

export default CompanyPage;
