// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { useEffect } from 'react';
import {
  Box,
  Button,
  FormField,
  Paragraph,
  Table,
  TableBody,
  TableRow,
  TextInput,
} from 'grommet';
import PropTypes from 'prop-types';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import { StatusIcon } from '../shared/component/StatusIcon';
import { useCustomerLocationServiceTypesQuery } from '../../core';
import Loader from '../shared/loader';
import GLBMLayer from '../shared/component/GLBMLayer';

const DeleteLocationConfirmDialog = (props) => {
  const {
    isFetching,
    data: services,
  } = useCustomerLocationServiceTypesQuery(props.customerId, props.location.id, {
    enabled: !!props.location.id,
  });

  useEffect(() => {
    if (!isFetching && !services.length) {
      props.onChange();
    }
  }, [isFetching, services]);

  const _renderServiceDetails = () => {
    const rows = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < services.length; i += 1) {
      const serviceTypeEnum = ServiceTypeStore.getService(services[i]);
      rows.push(
        <TableRow key={services[i]}>
          <td key={services[i]}><strong>{serviceTypeEnum ? serviceTypeEnum.label : services[i]}</strong></td>
        </TableRow>,
      );
    }

    return (
      <Box margin={{ top: 'small' }}>
        <Box background='light-2' flex={false}>
          <Table>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </Box>
      </Box>
    );
  };

  let closeControl;
  if (props.onClose) {
    closeControl = (
      <Button
        label='Cancel'
        onClick={() => props.onClose()}
        secondary={true}
      />
    );
  }

  if (!isFetching && !services.length) {
    return <></>;
  }

  if (isFetching) {
    return (
      <GLBMLayer
        position='center'
      >
        <Box align='center' justify='center' pad='small'>
          <Loader text='Validating Location Removal ...' />
        </Box>
      </GLBMLayer>
    );
  }

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      closer={true}
      onClose={() => props.onClose()}
      onEsc={() => props.onClose()}
      onClickOutside={() => props.onClose()}
      overlayClose={true}
      title='Remove Location?'
    >
      <Box pad='medium' flex={true} width='medium'>
        <Box direction='row' align='center'>
          <StatusIcon value='warning' size='medium' />
          <Box margin={{ left: 'small' }}>
            <Paragraph>
              Warning: This location is used by the following services:
            </Paragraph>
          </Box>
        </Box>
        {_renderServiceDetails()}
        <Box flex={true}>
          <Paragraph>
            Removing this location may result in unconfigured services and may also affect historical Monthly Charges
            reports. Only remove this location if you are planning to resolve any unconfigured services after applying
            this change.
          </Paragraph>
          <FormField
            key='locationEditorId'
            htmlFor='locationEditorId'
            label='Name'
          >
            <TextInput
              id='locationId'
              name='name'
              value={props.location.name}
            />
          </FormField>
        </Box>
      </Box>
      <Box flex={false} justify='between' direction='row' pad='medium'>
        <Button primary={true} onClick={() => props.onChange()} label='OK' />
        {closeControl}
      </Box>
    </GLBMLayer>
  );
};

DeleteLocationConfirmDialog.propTypes = {
  location: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteLocationConfirmDialog;
