// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import { cloneDeep } from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import ReactRouterPrompt from 'react-router-prompt';
import moment from 'moment';
import {
  Box,
  Button,
  Form,
  FormField,
  Main,
  MaskedInput,
  Notification,
  Select,
  TextInput,
} from 'grommet';
import { MailOption } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import SaveChangesDialog from '../shared/dialogs/SaveChangesDialog';
import RestrictedServices from '../shared/component/RestrictedServices';
import {
  useCustomersQuery,
  useUserQuery,
  useUserRoleQuery,
  useUserUpdateMutate,
} from '../../core';
import GLBMSaving from '../shared/component/GLBMSaving';
import { roles } from '../shared/constants/Permissions';
import { useRoleChecker } from '../shared/hooks/permissions';
import { UserType } from '../shared/constants/UserType';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import UserStore from '../stores/UserStore';
import UserCustomerPage from './UserCustomerPage';
import GLBMHeading from '../shared/component/GLBMHeading';

import { isUnassignedRole } from '../stores/UserTypeStore';

const UserPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [originalUser, setOriginalUser] = useState({});
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(undefined);
  const [customers, setCustomers] = useState(undefined);

  const me = useMemo(() => UserStore.getUser(), []);
  const { isRole } = useRoleChecker();

  const {
    data: initialUser,
    isFetched: fetchedUser,
  } = useUserQuery(userId, {
    onError: ((err) => {})
  });

  useEffect(() => {
    if (fetchedUser && initialUser) {
      setUser(initialUser);
      setOriginalUser(cloneDeep(initialUser));
    }
  }, [initialUser, fetchedUser]);

  const {
    data: userRoles,
  } = useUserRoleQuery();

  const onUserSaveSuccess = useCallback(() => {
    // initialize the user object in the global store before we leave:
    setOriginalUser(prevState => ({
      ...prevState,
      ...user,
    }));
    setTimeout(() => {
      navigate('/users', { state: { savedUser: { ...user } } });
    }, 100);
  }, [user, navigate]);

  const onUserSaveError = useCallback((error) => {
    console.log('error', error);
    if (error.hasOwnProperty('validationErrors')) {
      error.validationErrors.forEach((error) => {
        const attribute = error.propertyName.substring(error.propertyName.lastIndexOf('.') + 1);
        errors[attribute] = [error.message];
      });
      setErrors(prevState => ({
        ...prevState,
        ...errors,
      }));
    } else if (error.hasOwnProperty('message')) {
      setResponse({
        status: 'critical',
        title: 'Save User Error',
        message: error.message
      });
    }
  }, []);

  const {
    mutate: saveUser,
    isPending: isSavingUser,
    error: userError,
  } = useUserUpdateMutate(userId, {
    onSuccess: onUserSaveSuccess,
    onError: onUserSaveError,
  });

  const _onCustomerListChange = (customers) => {
    if (customers) {
      const role = (user.role ? user.role : null);
      const customerMap = role === 'ASM'
        ? customers.reduce((customerMap, c) => {
          if (c.status !== 'REQUEST') {
            customerMap[c.id] = {
              id: c.id,
              name: c.name,
            };
          }
          return customerMap;
        }, {})
        : customers.reduce((customerMap, c) => {
          customerMap[c.id] = {
            id: c.id,
            name: c.name,
          };
          return customerMap;
        }, {});
      setCustomers(customerMap);
    }
  };

  const {
    data: fetchedCustomers,
    isFetching: fetchingCustomers,
    isSuccess: isCustomerSuccess,
  } = useCustomersQuery({
    enabled: !!user,
  });

  useEffect(() => {
    if (isCustomerSuccess) {
      _onCustomerListChange(fetchedCustomers);
    }
  }, [isCustomerSuccess, fetchedCustomers]);

  const services = useMemo(() => ServiceTypeStore.getServices(), []);

  const _onChange = (event) => {
    const attribute = event.target.getAttribute('name');
    switch (attribute) {
      case 'restrictBillingAccounts':
        user.restrictBillingAccounts = event.target.checked;
        if (!user.restrictBillingAccounts && user.restrictedBillingAccounts) {
          user.restrictedBillingAccounts.forEach((c) => {
            c.action = 'DELETE';
          });
        }
        break;
      default:
        user[attribute] = event.target.value;
    }

    const updatedErrors = cloneDeep(errors);
    delete updatedErrors[attribute];
    updatedErrors[attribute] = [];

    // validate email to be valid email as the user types:
    switch (attribute) {
      case 'firstName':
      case 'lastName':
      case 'email':
        if (user[attribute].length === 0) {
          updatedErrors[attribute].push('Required');
        }
        break;
      case 'managerEmail':
        if (user[attribute].length !== 0) {
          const hpeEmail = /^[A-Za-z0-9._%+-]+@hpe\.com$/i;
          if (!hpeEmail.test(user[attribute])) {
            updatedErrors[attribute].push('Invalid Email');
          }
        }
        break;
      default:
        break;
    }
    setUser(prevState => ({
      ...prevState,
      ...user,
    }));
    setErrors(updatedErrors);
  };

  /**
   * Called when the end user modifies the
   * @private
   */
  const _onUserCustomersChange = (role, customers) => {
    // eslint-disable-next-line default-case
    switch (role) {
      case 'ASM':
      case 'SERVICE_DEV':
        setUser(prevState => ({
          ...prevState,
          assignedCustomers: customers,
        }));
        break;
    }
  };

  const _onUserServicesChange = (services) => {
    setUser(prevState => ({
      ...prevState,
      restrictedServices: services,
    }));
  };

  const _onRoleChange = (event) => {
    setUser(prevState => ({
      ...prevState,
      roles: undefined,
      role: event.value.value,
    }));
  };

  const _getTotalErrors = (errors) => {
    let totalErrors = 0;
    for (const property in errors) {
      totalErrors += (errors[property].length);
    }
    return totalErrors;
  };

  const _getError = (errors) => {
    if (!errors || errors.length === 0) {
      return undefined;
    }
    return errors.join(', ');
  };

  const _onSubmitUser = () => {
    setResponse(undefined);
    let noErrors = (_getTotalErrors(errors) === 0);

    if (!user.firstName) {
      errors.firstName = ['Required'];
      noErrors = false;
    }
    if (!user.lastName) {
      errors.lastName = ['Required'];
      noErrors = false;
    }
    if (!user.email) {
      errors.email = ['Required'];
      noErrors = false;
    }
    if (!user.role) {
      errors.roles = ['Required'];
      noErrors = false;
    }

    if (!noErrors) {
      setErrors(prevState => ({
        ...prevState,
        ...errors,
      }));
      return;
    }

    // Save to Backend
    saveUser({ ...user });
  };

  const _onClickCancel = () => {
    navigate(-1);
  };

  const _isDirty = useMemo(() => (JSON.stringify(user) !== JSON.stringify(originalUser)), [user, originalUser]);

  const roleOptions = useMemo(() => {
    const userTypeEnum = UserType.enumValueOf(me.role);
    if (userRoles && userRoles.length) {
      const { canSetRoles } = userTypeEnum;
      return userRoles
        .filter(({ id }) => canSetRoles.indexOf(id) !== -1)
        .map(({ id, displayName }) => ({ value: id, label: displayName }));
    }
    return [];
  }, [userRoles]);

  const _getRoleValue = (role) => {
    if (roleOptions && role) {
      return roleOptions?.filter(r => r.value === role)[0];
    }
    return role;
  };

  const _renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response?.status || 'critical'}
          title={response.title}
          message={response.message}
          onClose={() => setResponse(undefined)}
        />
      );
    }
    return message;
  };

  const _renderRoleField = (role, roleOptions, isEditable) => (
    <FormField
      label={`Role${isEditable ? '' : ' (read only)'}`}
      error={_getError(errors.roles)}
    >
      {(isEditable) ? (
        <Select
          labelKey='label'
          valueKey='value'
          placeholder='None'
          options={roleOptions}
          value={_getRoleValue(role)}
          id={IDUtil.getId('UserEditorRoleInput')}
          onChange={event => _onRoleChange(event)}
          disabled={!isEditable}
        />
      ) : (
        <TextInput
          id={IDUtil.getId('UserEditorRoleInput')}
          name='role'
          type='role'
          value={_getRoleValue(role)?.label || role}
          disabled={true}
        />
      )}
    </FormField>
  );

  const role = user.role ? user.role : null;

  const userRoleFlags = useMemo(
    () => userRoles?.filter(option => (option.id === role))[0],
    [role, userRoles]
  );

  if (!fetchedUser) {
    return (<Box data-testid='loading' />);
  }
  const isAdmin = isRole(roles.SUPER);
  const isSupport = isRole(roles.SUPPORT);
  const isEditUnassigned = (isSupport || isAdmin) && isUnassignedRole(originalUser?.role);

  return (
    (
      <Main direction='column' fill='vertical' overflow='hidden'>
        <GLBMHeading
          back={() => navigate(-1)}
          title='Edit Existing User'
        />
        <Box flex={true} pad={{ horizontal: 'medium' }} overflow='auto'>
          <Box flex={false} direction='row' wrap={true}>
            <Box basis='1/2' width={{ min: 'medium' }} pad={{ right: 'medium' }}>
              <Form>
                <FormField label={`First Name${!isEditUnassigned ? '' : ' (read only)'}`} error={_getError(errors.firstName)}>
                  <TextInput
                    id={IDUtil.getId('UserEditorFirstNameInput')}
                    name='firstName'
                    value={user.firstName || ''}
                    onChange={_onChange}
                    disabled={isEditUnassigned}
                  />
                </FormField>
                <FormField label={`Last Name${!isEditUnassigned ? '' : ' (read only)'}`} error={_getError(errors.lastName)}>
                  <TextInput
                    id={IDUtil.getId('UserEditorLastNameInput')}
                    name='lastName'
                    value={user.lastName || ''}
                    onChange={_onChange}
                    disabled={isEditUnassigned}
                  />
                </FormField>
                <FormField
                  key='email'
                  htmlFor='email'
                  label='Email (read only)'
                  error={_getError(errors.email)}
                >
                  <TextInput
                    id={IDUtil.getId('UserEditorEmailInput')}
                    name='email'
                    type='email'
                    value={user.email || ''}
                    onChange={_onChange}
                    disabled={true}
                    reverse={true}
                    icon={<MailOption size='small' />}
                  />
                </FormField>
                {_renderRoleField(role, roleOptions, (isEditUnassigned || isAdmin) && me.id !== user.id)}
                <FormField
                  label='Manager Email ID'
                  htmlFor='managerEmail'
                  error={_getError(errors.managerEmail)}
                >
                  <MaskedInput
                    id={IDUtil.getId('UserEditorManagerEmailInput')}
                    icon={<MailOption size='small' />}
                    name='managerEmail'
                    mask={[
                      {
                        regexp: /^[\w\-_.]+$/,
                        placeholder: 'example',
                      },
                      { fixed: '@hpe.com' }
                    ]}
                    value={user.managerEmail || ''}
                    onChange={_onChange}
                    reverse={true}
                    data-testid='UserEditorManagerEmailInput'
                  />

                </FormField>
              </Form>
            </Box>
            <Box basis='1/2' width={{ min: 'medium' }} pad={{ right: 'medium' }}>
              <FormField
                label='Last login (readonly)'
                htmlFor='user-lastLogin'
              >
                <TextInput
                  id='user-lastLogin'
                  value={user.lastLogin && moment.utc(user.lastLogin).format('lll Z')}
                  disabled={true}
                />
              </FormField>
              <FormField
                label='Access requested on (readonly)'
                htmlFor='user-requestAccessTime'
              >
                <TextInput
                  id='user-requestAccessTime'
                  value={user.requestedAccessTime && moment.utc(user.requestedAccessTime).format('lll Z')}
                  disabled={true}
                />
              </FormField>
              <FormField
                label='Access granted on (readonly)'
                htmlFor='user-approvalGrantedOn'
              >
                <TextInput
                  id='user-approvalGrantedOn'
                  value={user.grantedAccessTime && moment.utc(user.grantedAccessTime).format('lll Z')}
                  disabled={true}
                />
              </FormField>
              <FormField
                label='Access approved by (readonly)'
                htmlFor='user-approvalGrantedBy'
              >
                <TextInput
                  id='user-approvalGrantedBy'
                  value={user.rolesGrantedBy?.email}
                  disabled={true}
                />
              </FormField>
              <FormField
                label='Access revoked on (readonly)'
                htmlFor='user-accessRevokedOn'
              >
                <TextInput
                  id='user-accessRevokedOn'
                  value={user.lastRevokedTime && moment.utc(user.lastRevokedTime).format('lll Z')}
                  disabled={true}
                />
              </FormField>
              <FormField
                label='Access revoked by (readonly)'
                htmlFor='user-accessRevokedBy'
              >
                <TextInput
                  id='user-accessRevokedBy'
                  value={user.lastRevokedBy?.email}
                  disabled={true}
                />
              </FormField>
              {user.lastRevokedNotes && user.lastRevokedNotes !== '' && (
              <FormField
                label='Access Revoked Notes (readonly)'
                htmlFor='user-accessRevokedNotes'
              >
                <TextInput
                  id='user-accessRevoked notes'
                  value={user.lastRevokedNotes}
                  disabled={true}
                />
              </FormField>
              )}
            </Box>
          </Box>
          {userRoleFlags?.restrictAccounts ? (
            <UserCustomerPage
              role={role}
              loading={fetchingCustomers}
              customers={customers}
              assigned={user.assignedCustomers || []}
              onChange={newCustomers => _onUserCustomersChange(role, newCustomers)}
            />
          ) : ''}
          {userRoleFlags?.restrictServices ? (
            <RestrictedServices
              services={services}
              assigned={user.restrictedServices || []}
              onChange={newServices => _onUserServicesChange(newServices)}
            />
          ) : ''}
        </Box>
        <Box
          border='top'
          direction='row'
          pad={{ horizontal: 'small', vertical: 'small' }}
          gap='small'
          flex={false}
        >
          <Button
            label='Save'
            id={IDUtil.getId('EditorViewToolbarSaveButton')}
            type='button'
            primary={true}
            onClick={_onSubmitUser}
            disabled={isSavingUser}
          />
          <Button
            label='Cancel'
            id={IDUtil.getId('EditorViewToolbarCancelButton')}
            type='button'
            secondary={true}
            onClick={_onClickCancel}
            disabled={isSavingUser}
          />
          <GLBMSaving saving={isSavingUser} error={userError} />
        </Box>
        <ReactRouterPrompt when={_isDirty}>
          {({ isActive, onConfirm, onCancel }) => (
            <SaveChangesDialog
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </ReactRouterPrompt>
        {_renderToast()}
      </Main>
    )
  );
};

UserPage.contextTypes = {
  router: PropTypes.object,
};

export default UserPage;
