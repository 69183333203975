// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePermissionChecker } from '../shared/hooks';
import { pagePermissions } from '../shared/constants/Permissions';

import PartnerEditorPage from './PartnerEditorPage';
import PartnerListPage from './PartnerListPage';
import PartnerTenantPage from './PartnerTenantPage';

const PartnerPage = () => {
  const { hasPermissions } = usePermissionChecker();
  return (
    <Routes>
      <Route key='listRoute' path='/' element={<PartnerListPage />} />
      { hasPermissions(pagePermissions.partners.add) && <Route key='addRoute' path='/add' element={<PartnerEditorPage />} /> }
      { hasPermissions(pagePermissions.partners.edit) && <Route key='editRoute' path='/:partnerId' element={<PartnerEditorPage />} /> }
      { hasPermissions(pagePermissions.partners.tenant) && <Route key='tenantRoute' path='/:partnerId/tenant' element={<PartnerTenantPage />} /> }
    </Routes>
  );
};

export default PartnerPage;
