// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import EncryptionKeyListPage from '../encryptionkeys/EncryptionKeyListPage';
import FeedPage from '../services/feeds/FeedPage';
import DeleteEquipmentList
  from '../services/service-delete/DeleteEquipmentList';
import ServiceEditPage from '../services/service-edit/ServiceEditContainer';
import ServicesListPage from '../services/ServicesListPage';
import CustomerPage from './CustomerPage';

import CustomersListPage from './CustomersListPage';
import ASMListPage from '../asms/ASMListPage';
import FixedCharges from '../fixedcharges/FixedCharges';
import FeatureListPage from './FeatureListPage';
import CapacityAlertSettingsPage from '../capacityalertsV2/CapacityAlertSettingsPage';
import CustomerTenantPage from '../tenant/CustomerTenantPage';
import PSAManagePage from './psa-project-info/PSAManagePage';

const CustomersPage = () => {
  const routes = useMemo(() => [
    <Route key='listRoute' path='/' element={<CustomersListPage />} />,
    <Route key='addRoute' path='/add' element={<CustomerPage />} />,
    <Route key='adminRoute' path='/:customerId/admins' element={<ASMListPage />} />,
    <Route key='chargesRoute' path='/:customerId/charges' element={<FixedCharges />} />,
    <Route key='featuresRoute' path='/:customerId/features' element={<FeatureListPage />} />,
    <Route key='alertsRoute' path='/:customerId/capacityalert' element={<CapacityAlertSettingsPage />} />,
    <Route key='tenantRoute' path='/:customerId/tenant' element={<CustomerTenantPage />} />,
    <Route key='psaRoute' path='/:customerId/psa' element={<PSAManagePage />} />,
    <Route key='editRoute' path='/:customerId' element={<CustomerPage />} />,
    <Route key='serviceList' path='/:customerId/services' element={<ServicesListPage />} />,
    <Route key='serviceWizard' path='/:customerId/services/:serviceId/:page?' element={<ServiceEditPage />} />,
    <Route key='deleteEquipment' path='/:customerId/equipments/:serviceId' element={<DeleteEquipmentList />} />,
    <Route key='feeds' path='/:customerId/feeds/:serviceId' element={<FeedPage />} />,
    <Route key='encryptionKeys' path='/:customerId/account-keys' element={<EncryptionKeyListPage />} />,
  ], []);

  return (
    <Routes>
      {routes}
    </Routes>
  );
};

export default CustomersPage;
