// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import WiseQuoteCreatePage from './WiseQuoteIdCreatePage';
import WiseQuoteIdDeletePage from './WiseQuoteIdDeletePage';
import WiseQuoteIdListPage from './WiseQuoteIdListPage';

const WiseQuotePage = () => (
  <Routes>
    <Route key='listRoute' path='/' element={<WiseQuoteIdListPage />} />
    <Route key='addRoute' path='/add' element={<WiseQuoteCreatePage />} />
    <Route key='editRoute' path='/edit/:id' element={<WiseQuoteCreatePage />} />
    <Route key='deleteRoute' path='/delete/:id' element={<WiseQuoteIdDeletePage />} />
  </Routes>
);
export default WiseQuotePage;
