// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Add, CircleQuestion, Trash } from 'grommet-icons';
import {
  Anchor,
  Box,
  Button,
  Select,
  Table, TableBody, TableCell, TableHeader, TableRow,
  Text,
} from 'grommet';
import { renderColumnTableCell } from '../shared/util/tableUtils';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import IDUtil from '../shared/util/IDUtil';
import CustomerListEditor from '../customers/CustomerListEditor';
import GLBMTooltip from '../shared/component/GLBMTooltip';
import Loader from '../shared/loader';
import { ListPlaceholder } from '../shared/component/ListPlaceholder';
import Toast from '../shared/component/Toast';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import { insertIf } from '../shared/util/BasicUtil';

class UserCustomerPage extends Component {
  constructor(props, context) {
    super(props);

    // BIND methods:
    this._onSortList = this._onSortList.bind(this);
    this._onRemoveClick = this._onRemoveClick.bind(this);
    this._layerClosed = this._layerClosed.bind(this);
    this._onCustomerListEditorApplied = this._onCustomerListEditorApplied.bind(this);
    this._onCustomerListRemoveConfirmed = this._onCustomerListRemoveConfirmed.bind(this);
    this._onAddCustomerClicked = this._onAddCustomerClicked.bind(this);
    this._onChangeRole = this._onChangeRole.bind(this);
    this._roleHeaderWithHelp = this._roleHeaderWithHelp.bind(this);

    this.state = {
      customers: undefined,
      sortProperties: ['name', 'customerId', 'asmRole'],
      sortIndex: 0,
      assigned: (props.assigned ? props.assigned : []),
      sortAscending: true,
      selectedIndex: props.selectedIndex,
    };
  }

  _getAction(index, customer) {
    return (
      <Anchor
        icon={<Trash />}
        label='Remove'
        id={IDUtil.getId('ASMListViewListRemoveButton', index)}
        onClick={this._onRemoveClick.bind(this, index, customer.customerId)}
      />
    );
  }

  _onSortList(index, ascending) {
    if (index >= this.state.sortProperties.length) {
      return;
    }

    let customers = this.state.assigned;
    const property = this.state.sortProperties[index];

    switch (property) {
      case 'name':
        const customerMap = this.props.customers;
        customers = customers.sort((a, b) => {
          const aName = customerMap[a.customerId].name.trim().toLowerCase();
          const bName = customerMap[b.customerId].name.trim().toLowerCase();
          return ((aName === bName) ? 0 : (aName < bName) ? -1 : 1);
        });
        break;
      default:
        customers = customers.sort((a, b) => ((a[property] === b[property]) ? 0 : (a[property] < b[property]) ? -1 : 1));
        break;
    }
    if (!ascending) {
      customers.reverse();
    }
    this.setState({ sortIndex: index, sortAscending: ascending });
  }

  _onRemoveClick(selectedIndex) {
    const layer = 'removeConfirm';
    this.setState({ layer, selectedIndex });
  }

  _layerClosed() {
    const layer = undefined;
    this.setState({ layer });
  }

  _onAddCustomerClicked() {
    const layer = 'addCustomer';
    this.setState({ layer });
  }

  _onCustomerListEditorApplied(updatedCustomers) {
    const layer = undefined;
    const { assigned } = this.state;
    const { role } = this.props;

    // look to see if we should add new items:
    for (var i = 0; i < updatedCustomers.length; i += 1) {
      let found = false;
      for (let j = 0; j < assigned.length; j += 1) {
        if (assigned[j].customerId === updatedCustomers[i]) {
          found = true;

          // if a customer was found but currently marked for deletion, mark it 'UPDATE':
          if (assigned[j].action === 'DELETE') {
            assigned[j].action = 'UPDATE';
          }
        }
      }

      // if not found, add it as ADD:
      if (!found) {
        const newCustObj = {
          customerId: updatedCustomers[i],
          action: 'ADD',
        };
        if (role === 'ASM' || role === 'SERVICE_DEV') {
          newCustObj.asmRole = 'READ';
        }
        assigned.push(newCustObj);
      }
    }

    // look for previously assigned customers that no longer are, mark as deleted:
    for (var i = 0; i < assigned.length; i += 1) {
      if (updatedCustomers.indexOf(assigned[i].customerId) === -1) {
        assigned[i].action = 'DELETE';
      }
    }

    this.setState({ layer, assigned }, function () {
      this.props.onChange(assigned);
    });
  }

  _onCustomerListRemoveConfirmed(data) {
    const { index } = data;
    const layer = undefined;
    const { assigned } = this.state;
    assigned[index].action = 'DELETE';
    this.setState({ layer, assigned }, function () {
      this.props.onChange(assigned);
    });
  }

  _onChangeRole(customer, event) {
    const { assigned } = this.state;
    for (let i = 0; i < assigned.length; i += 1) {
      if (assigned[i].customerId === customer.customerId) {
        assigned[i].asmRole = event.value;
        if (assigned[i].action !== 'ADD') {
          assigned[i].action = 'UPDATE';
        }
      }
    }
    this.setState({ assigned }, function () {
      this.props.onChange(assigned);
    });
  }

  _getRoleValue(role) {
    const roles = [{ value: 'READ', label: 'Read' }, { value: 'EDIT', label: 'Edit' }, { value: 'SUPER_EDIT', label: 'Super Edit' }];
    return roles.filter(r => r.value === role)[0] || { value: 'READ', label: 'Read' };
  }

  _renderConfirmationDetails(assignedCustomer) {
    const customer = this.props.customers[assignedCustomer.customerId];
    const { role } = this.props;
    return (
      <Box margin={{ top: 'small' }}>
        <GLBMNameValueList
          title='Selected Billing Account'
          data={[
            { label: 'Billing Account Name', value: customer ? customer.name : '-' },
            { label: 'Billing ID', value: customer ? customer.id : assignedCustomer.customerId },
            ...insertIf(role === 'ASM' || role === 'SERVICE_DEV', [{ label: 'Role', value: this._getRoleValue(assignedCustomer.asmRole).label }]),
          ]}
        />
      </Box>
    );
  }

  _renderLayer() {
    let result;
    const { layer } = this.state;
    const { customers } = this.props;
    if (layer) {
      if (layer === 'removeConfirm') {
        result = (
          <ConfirmationDialog
            data={{ index: this.state.selectedIndex }}
            title='Are You Sure?'
            submitLabel='Yes, remove the Billing Account'
            cancelLabel='Cancel'
            onClose={this._layerClosed}
            onChange={this._onCustomerListRemoveConfirmed}
            details={this._renderConfirmationDetails(this.state.assigned[this.state.selectedIndex])}
          />
        );
      } else if (layer === 'addCustomer') {
        const customerMap = this.state.assigned.reduce(
          (customers, c) => {
            if (c.action !== 'DELETE') {
              customers.push(c.customerId);
            }
            return customers;
          },
          [],
        );

        result = (
          customers
          && (
          <CustomerListEditor
            onClose={this._layerClosed}
            onChange={this._onCustomerListEditorApplied}
            items={
              Object.keys(customers).map(x => customers[x])
}
            selected={customerMap}
          />
          )
        );
      }
    }
    return result;
  }

  _noRowsElement(totalCustomers) {
    if (this.props.loading) {
      return (
        <Box direction='row' align='center' gap='small' justify='center' fill={true}>
          <Loader text='Loading Billing Accounts. Please wait ...' />
        </Box>
      );
    }
    if (totalCustomers === 0) {
      return (
        <ListPlaceholder
          emptyMessage='You have not added any Billing Accounts to this user.'
          unfilteredTotal={0}
          filteredTotal={1}
        />
      );
    }

    return '';
  }

  _renderToast() {
    let message = '';

    if (this.state.saveResponse) {
      message = (
        <Toast open={this.state.saveResponse} status='critical'>
          {this.state.saveResponse.statusText}
        </Toast>
      );
    }
    return message;
  }

  _roleHeaderWithHelp() {
    return (
      <Box direction='row'>
        <Box margin={{ right: 'small' }}>Role</Box>
        <GLBMTooltip
          content={(
            <Box>
              <li style={{ color: 'white', fontWeight: 'normal' }}>Choose Super Edit if you are responsible for creating and maintaining service configurations, pricing and capacity terms.</li>
              <li style={{ color: 'white', fontWeight: 'normal' }}>Choose Edit if you are responsible for pricing and capacity updates.</li>
              <li style={{ color: 'white', fontWeight: 'normal' }}>Choose Read if you need visibility to the billing account but you don't need to update the billing configurations.</li>
            </Box>
)}
        >
          <Box>
            <CircleQuestion color='status-unknown' />
          </Box>
        </GLBMTooltip>
      </Box>
    );
  }

  render() {
    const {
      loading, role, customers, isRequest,
    } = this.props;
    const { assigned } = this.state;
    const rows = [];
    const options = [
      { value: 'READ', label: 'Read' },
      { value: 'EDIT', label: 'Edit' },
      { value: 'SUPER_EDIT', label: 'Super Edit' },
    ];

    if (!loading && assigned && assigned.length && customers) {
      assigned.forEach((customer, index) => {
        if (customer.action !== 'DELETE') {
          rows.push(
            <TableRow key={customer.customerId}>
              <TableCell>{customers && customers.hasOwnProperty(customer.customerId) ? customers[customer.customerId].name : '-'}</TableCell>
              <TableCell>{customer.customerId}</TableCell>
              {(role === 'ASM' || role === 'SERVICE_DEV')
                && (
                <TableCell>
                  <Select
                    labelKey='label'
                    valueKey={{ key: 'value', reduce: true }}
                    placeholder='None'
                    options={options}
                    value={customer.asmRole}
                    id={IDUtil.getId('ASMListViewListRoleDropDown', index)}
                    onChange={event => this._onChangeRole(customer, event)}
                  />
                </TableCell>
                )}
              <TableCell>
                {this._getAction(index, customer)}
              </TableCell>
            </TableRow>,
          );
        }
      });
    }

    // if no rows:
    const noRows = this._noRowsElement(rows.length);

    return (
      <Box
        direction='column'
        margin={{ top: 'large' }}
        style={{ minHeight: '300px' }}
        flex='grow'
      >
        <GLBMHeading
          title='Assigned Billing Accounts'
          actions={[
            <Button
              kind='toolbar'
              icon={<Add />}
              id={IDUtil.getId('RecipientListToolbarAddButton')}
              onClick={() => this._onAddCustomerClicked()}
              key='addButton'
              label='Add'
            />
          ]}
          size='large'
          pad={{ vertical: 'small', horizontal: 'none' }}
          inline={true}
        />
        <Box margin={{ bottom: 'small' }}>
          {(role === 'ASM' || role === 'SERVICE_DEV')
            && (
            <Text fill={true} margin='none'>
              {isRequest
                ? 'Select Billing Account(s) that you are assigned to and the role for each billing account.'
                : `Add billing accounts to give the ${role === 'SERVICE_DEV' ? 'Service Developer' : 'ASM'} access to service configuration, analytics and alerts`}
            </Text>
            )}
        </Box>
        <Box flex={true}>
          <Box
            border='top'
            flex={true}
          >
            <Table
              responsive={true}
              scrollable={false}
            >
              <TableHeader>
                {renderColumnTableCell(role === 'ASM' || role === 'SERVICE_DEV' ? ['Billing Account Name', 'Billing ID', isRequest ? this._roleHeaderWithHelp() : 'Role', 'Action']
                  : ['Billing Account Name', 'Billing ID', 'Action'])}
              </TableHeader>
              <TableBody>
                {rows}
              </TableBody>
            </Table>
            {noRows}
          </Box>
        </Box>
        {this._renderLayer()}
      </Box>
    );
  }
}

UserCustomerPage.contextTypes = {
  router: PropTypes.object,
};

UserCustomerPage.defaultProps = {
  layer: undefined,
  loadAdmins: true,
  showTip: true,
  adminList: [],
  selectedIndex: undefined,
  isRequest: false,
};

const mapStateToProps = store => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserCustomerPage);
