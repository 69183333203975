// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';

import { Route, Routes } from 'react-router-dom';

import UserPage from './UserPage';
import UsersListPage from './UsersListPage';

const UsersPage = () => (
  <Routes>
    <Route path='/' element={<UsersListPage />} />
    <Route path='/:userId' element={<UserPage />} />
  </Routes>
);

export default UsersPage;
