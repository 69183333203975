// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import URLUtil from '../../shared/util/URLUtil';
import { updateStateAndSession } from '../../session/sessionUtil';

export const INVALIDATE_JOBS = 'INVALIDATE_JOBS';
export const REQUEST_JOBS = 'REQUEST_JOBS';
export const SUCCESS_JOBS = 'SUCCESS_JOBS';
export const FAILURE_JOBS = 'FAILURE_JOBS';
export const UPDATE_JOBS_FILTERS = 'UPDATE_JOBS_FILTERS';

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export function invalidateJobs() {
  return { type: INVALIDATE_JOBS };
}

const requestJobs = filters => ({
  type: REQUEST_JOBS,
  filters,
  items: [],
  error: false,
  loading: true,
});

const successJobs = res => ({
  type: SUCCESS_JOBS,
  items: res,
  error: false,
  loading: false,
});

const failureJobs = error => ({
  type: FAILURE_JOBS,
  items: [],
  error,
  loading: false,
});

export function updateFilters(filters) {
  return {
    type: UPDATE_JOBS_FILTERS,
    filters,
  };
}

export const updateFiltersAndSession = updateStateAndSession(updateFilters);

export const getJobList = (filters, path) => (dispatch) => {
  dispatch(requestJobs(filters));
  new Promise((resolve) => {
    fetch(`${path}${URLUtil.objectToQueryParams(filters, ['to', 'from', 'type', 'status', 'customerId'])}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      credentials: 'include',
    }).then(handleErrors)
      .then(r => r.json())
      .then(resolve)
      .catch((err) => {
        dispatch(failureJobs(err));
      });
  }).then((res) => {
    dispatch(successJobs(res));
  });
};
