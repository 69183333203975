// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { produce } from 'immer';

import {
  Anchor,
  Button,
  Main,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMSearch from '../shared/component/GLBMSearch';
import { updateFiltersAndSession } from './redux/ServiceMappingsActions';
import ServiceMappingsFilter from './ServiceMappingsFilter';
import ServiceMappingEditor from './ServiceMappingEditor';
import FilterControl from '../shared/component/FilterControl';
import GLBMHeading from '../shared/component/GLBMHeading';
import { useCustomersQueryMap, useServiceMappingsQuery } from '../../core';

const getServiceTypes = mappings => [...new Set((mappings || []).map(item => item.serviceName))];

const ServiceMappingsListPage = ({
  setSessionFilters,
  serviceMappings = {
    filters: {
      list: { searchText: '', sort: { property: 'serviceName', direction: 'asc', external: true } },
      panel: {},
    },
  }
}) => {
  const [filters, setFilters] = useState({ ...serviceMappings.filters });
  const { data: customerList } = useCustomersQueryMap();

  const {
    data: serviceMappingList,
    isFetching: loading,
    refetch: _refreshServiceMappings,
  } = useServiceMappingsQuery(filters, {
    enabled: !!customerList,
  });
  const serviceTypes = getServiceTypes(serviceMappingList);
  const [filterActive, setFilterActive] = useState(false);
  const [selectedServiceMapping, setSelectedServiceMapping] = useState();
  const index = {
    count: 20,
    total: serviceMappingList?.length || 0,
  };

  const _onSearchChange = (event) => {
    const search = event;
    const newList = { ...filters.list, searchText: search };
    const newFilters = { ...filters, list: newList };
    setFilters(newFilters);
    setSessionFilters(newFilters);
  };

  const _onSortColumn = (sort) => {
    const updatedFilters = produce(filters, (draft) => {
      draft.list.sort = sort;
      return draft;
    });
    setFilters(updatedFilters);
    setSessionFilters(updatedFilters);
  };

  const _filterMappings = () => {
    const panel = filters?.panel || {};
    const searchText = filters?.list?.searchText;

    const filterByText = (!!searchText);
    const filterByFilter = (Object.keys(panel).length > 0);

    if (!searchText && Object.keys(panel).length === 0) {
      return serviceMappingList;
    }

    const searchTextInternal = searchText.toLowerCase();

    const results = [];
    for (let i = 0; i < serviceMappingList?.length; i += 1) {
      const mapping = serviceMappingList[i];

      let matchOnText;
      let matchOnFilter;

      // see if the customer is a match by text:
      if (filterByText) {
        if ((mapping.hasOwnProperty('id') && mapping.id !== undefined ? mapping.id.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
          || (mapping.hasOwnProperty('serviceInstanceId') && mapping.serviceInstanceId !== undefined ? mapping.serviceInstanceId.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
          || (mapping.hasOwnProperty('glhcTenantId') && mapping.glhcTenantId !== undefined ? mapping.glhcTenantId.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
          || (mapping.hasOwnProperty('customerName') && mapping.customerName !== undefined ? mapping.customerName.toLowerCase().indexOf(searchTextInternal) !== -1 : false)
          || (mapping.hasOwnProperty('billingId') && mapping.billingId !== undefined ? mapping.billingId.toLowerCase().indexOf(searchTextInternal) !== -1 : false)) {
          matchOnText = true;
        }
      }

      // see if the customer is a match by panel:
      if (filterByFilter) {
        matchOnFilter = true; // default to true, and flip to false when we find our first negative result:

        for (const key in panel) {
          if (panel.hasOwnProperty(key)) {
            const value = panel[key];
            switch (key) {
              case 'status':
                if ((value === 'ASSIGNED' && !mapping.billingId) || (value === 'UNASSIGNED' && mapping.billingId)) {
                  matchOnFilter = false;
                }
                break;
              case 'service':
                if (value !== mapping.serviceName) {
                  matchOnFilter = false;
                }
                break;
              default:
                console.error('Unsupported Customer Filter Type: ', key);
            }
          }
        }
      }

      // if both search text + filter is specified, then they both needs to be found for a customer to match:
      if (filterByText && filterByFilter) {
        if (matchOnText && matchOnFilter) {
          results.push(mapping);
        }
      } else if (filterByText) {
        if (matchOnText) {
          results.push(mapping);
        }
      } else if (filterByFilter) {
        if (matchOnFilter) {
          results.push(mapping);
        }
      }
    }
    return results;
  };

  const _onFilterActivate = () => {
    setFilterActive(true);
  };

  const _onFilterDeactivate = () => {
    setFilterActive(false);
  };

  const _onViewDetails = (selectedServiceMapping, refreshList) => {
    setSelectedServiceMapping(selectedServiceMapping);
    if (refreshList) {
      _refreshServiceMappings();
    }
  };

  const _onFilterChange = (filterPanel) => {
    const updatedFilters = produce(filters, (draft) => {
      draft.panel = filterPanel;
      return draft;
    });
    setFilters(updatedFilters);
    setFilterActive(false);
    setSessionFilters(updatedFilters);
  };

  const _renderLayer = () => {
    let layer;
    if (filterActive) {
      layer = (
        <ServiceMappingsFilter
          onClose={_onFilterDeactivate}
          onChange={_onFilterChange}
          filter={filters?.panel}
          serviceTypes={serviceTypes}

        />
      );
    } else if (selectedServiceMapping) {
      const serviceMapping = selectedServiceMapping;

      layer = (
        <ServiceMappingEditor
          serviceMapping={serviceMapping}
          customers={customerList}
          filters={filters}
          onClose={_onViewDetails}
          onSubmit={() => {
            _onViewDetails(undefined, true);
          }}
        />
      );
    }
    return layer;
  };

  const _getColumns = () => [
    {
      property: 'serviceName',
      header: 'Service Name',
    },
    {
      property: 'id',
      header: 'Service Instance ID',
      primary: true,
    },
    {
      property: 'glhcTenantId',
      header: 'GLC Tenant ID',
    },
    {
      property: 'customerName',
      header: 'GLC Tenant Name',
    },
    {
      property: 'billingId',
      header: 'Billing ID',
    },
    {
      property: 'actions',
      header: 'Actions',
      render: datum => (<Anchor key='details' onClick={() => _onViewDetails(datum, false)}>{datum.billingId ? 'Change' : 'Assign'}</Anchor>),
      sortable: false,
    },
  ];

  const { searchText, sort } = filters?.list || {};
  const filteredMappings = _filterMappings();
  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Service Mappings'
        search={[
          <GLBMSearch
            key='searchText'
            value={searchText}
            onChange={_onSearchChange}
          />,
          <FilterControl
            key='filterCntrl'
            filters={filters?.panel}
            onFilter={_onFilterActivate}
            onClear={() => _onFilterChange({ })}
          />,
        ]}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              _refreshServiceMappings();
            }}
            a11yTitle='Refresh Audit List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={loading}
          />,
        ]}
      />
      <GLBMDataSummary total={index.total} filtered={filteredMappings?.length} />
      <GLBMDataTable
        searchText={searchText}
        data={filteredMappings || []}
        columns={_getColumns()}
        loading={loading}
        total={index.total}
        sort={sort}
        onSort={_onSortColumn}
      />
      {_renderLayer()}
    </Main>
  );
};

ServiceMappingsListPage.propTypes = {
  setSessionFilters: PropTypes.func.isRequired,
  serviceMappings: PropTypes.shape({
    filters: PropTypes.shape({
      list: PropTypes.shape({
        searchText: PropTypes.string,
        sort: PropTypes.shape({
          property: PropTypes.string,
          direction: PropTypes.string,
        }),
      }),
      panel: PropTypes.shape({}),
    }),
  }),
};

const mapStateToProps = store => ({
  serviceMappings: store.serviceMappings.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setSessionFilters: updateFiltersAndSession,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceMappingsListPage);
