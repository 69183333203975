// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
/* eslint-disable no-param-reassign */

import {
  Box, Button, Main, Notification, Text
} from 'grommet';
import { Refresh } from 'grommet-icons';
import { produce } from 'immer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import Highlight from 'react-highlighter';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useCustomersQuery, useGetJobsListQuery } from '../../core';
import CustomerSelector from '../shared/component/CustomerSelector';
import Duration from '../shared/component/Duration';
import FilterControl from '../shared/component/FilterControl';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMSearch from '../shared/component/GLBMSearch';
import {
  SearchTextContextProvider,
} from '../shared/component/HighlightUsingContext';
import OptionalAnchor from '../shared/component/OptionalAnchor';
import { StatusIcon } from '../shared/component/StatusIcon';
import { pagePermissions } from '../shared/constants/Permissions';
import IDUtil from '../shared/util/IDUtil';
import JobsFilter from './JobsFilter';
import { updateFiltersAndSession } from './redux/JobActions';

const JobsListPage = ({
  setSessionFilters = () => {},
  jobs = {
    error: null,
    filters: {},
    items: [],
  },
  customer = [],
}) => {
  const navigate = useNavigate();

  const [filterActive, setFilterActive] = useState(false);
  const [filters, setFilters] = useState(jobs?.filters);
  const [customerList, setCustomerList] = useState([]);
  const [customerMap, setCustomerMap] = useState({});
  const [response, setResponse] = useState(undefined);
  const [searchText, setSearchText] = useState('');
  const [reFetchJobs, setRefetch] = useState(false);

  const {
    data: customerData,
  } = useCustomersQuery();

  const {
    data: jobsList,
    refetch: refreshJobsList,
    isFetching: jobsLoading,
  } = useGetJobsListQuery(
    filters?.panel.to,
    filters?.panel.from,
    filters?.panel.type,
    filters?.panel.status,
    filters?.customerId,
  );
  const jobTypeMap = {
    charge: 'Charging',
    fill: 'Filling',
    load: 'Loading',
    migrate: 'Migrating',
    other: 'Other',
    sync: 'Syncing',
    transform: 'Transforming',
  };

  const jobStatusMap = {
    processing: 'Processing',
    failed: 'Failed',
    succeeded: 'Succeeded',
    terminated: 'Terminated',
    aborted: 'Aborted',
  };

  useEffect(() => {
    if (jobs?.items) {
      setResponse(
        jobs.error
          ? {
            status: 'critical',
            title: 'Fetch Jobs Error',
            message: jobs.error.message,
          }
          : response,
      );
    }
  }, [jobs]);

  useEffect(() => {
    const customers = customerData || [];
    const customerList = [];
    if (customers) {
      // sort them:
      customers.sort((a, b) => {
        const aTitle = (a.name || a.id);
        const bTitle = (b.name || b.id);
        if (aTitle.toLowerCase() <= bTitle.toLowerCase()) {
          return -1;
        }
        return 1;
      });
    }
    customers.forEach((customer) => {
      const id = [];
      if (customer.name) {
        id.push(
          <small key={customer.id}>
            <em className='secondary'>
              &nbsp;&nbsp;&nbsp;(
              {customer.id}
              )
            </em>
          </small>,
        );
      }
      const item = {
        label: (
          <Box direction='row' align='center' justify='between' id={IDUtil.getId(`BillingAccounts_${customer.id}`)}>
            <span>{customer.name || customer.id}</span>
            {id}
          </Box>),
        sub: customer.name || customer.id,
        value: customer.id,
      };
      customerList.push(item);
      customerMap[customer.id] = (customer.name || customer.id);
    });
    setCustomerMap({ ...customerMap });
    setCustomerList([...customerList]);
  }, [customer, customerData]);

  const filterJobs = (searchText, jobsListLocal) => {
    if (!searchText) {
      return jobsListLocal;
    }
    return jobsListLocal?.filter((job) => {
      const sub = customerList.find(el => el.value === job.accountId)?.sub;

      const jobSearchText = `${job.id}${job.runBy}${job.accountId}${sub}${jobStatusMap[job.status]}${jobTypeMap[job.type]}`;

      return jobSearchText.toLowerCase()
        .includes(searchText.toLowerCase());
    });
  };
  const onFilterActivate = () => {
    setFilterActive(true);
  };
  const onFilterDeactivate = () => {
    setFilterActive(false);
  };
  const onSelectCustomer = (option) => {
    const updatedFilters = produce(filters, (draft) => {
      if (option.value && option.value !== 'All') {
        // eslint-disable-next-line no-param-reassign
        draft.customerId = option.value;
      } else {
        delete draft.customerId;
      }
      return draft;
    });
    setFilters({ ...updatedFilters });
    setSessionFilters(updatedFilters);
    setRefetch(true);
  };

  const onUpdateFilter = (filterPanel) => {
    const updatedFilters = produce(filters, (draft) => {
      draft.panel = filterPanel;
      return draft;
    });
    setFilters({ ...updatedFilters });
    setFilterActive(false);
    setRefetch(true);
    setSessionFilters(updatedFilters);
  };

  useEffect(() => {
    if (reFetchJobs) {
      refreshJobsList();
      setRefetch(false);
    }
  }, [reFetchJobs]);

  const onSearchChange = useCallback((value) => {
    const search = value;
    const newList = { ...filters?.list };
    newList.searchText = search;
    const newFilters = { ...filters, list: newList };
    setSearchText(search);
    setFilters({ ...newFilters });
    setSessionFilters({ ...newFilters });
  }, [filters]);

  const renderJobStatus = (status) => {
    switch (status) {
      case 'processing':
        return (
          <Box key={1} direction='row' gap='small' align='center'>
            <StatusIcon
              value='unknown'
              size='small'
            />
            <span>{jobStatusMap[status]}</span>
          </Box>
        );
      case 'failed':
      case 'terminated':
      case 'aborted':
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon
              value='critical'
              size='small'
            />
            <span>{jobStatusMap[status]}</span>
          </Box>
        );
      case 'succeeded':
        return (
          <Box direction='row' gap='small' align='center'>
            <StatusIcon
              value='ok'
              size='small'
            />
            <span>{jobStatusMap[status]}</span>
          </Box>
        );
      default:
        return status;
    }
  };

  const getColumns = () => [
    {
      property: 'status',
      header: 'Status',
      render: datum => <Text id={IDUtil.getId('Status', datum.index)}>{renderJobStatus(datum.status)}</Text>,
      size: 'xsmall',
    },
    {
      property: 'type',
      header: 'Type',
      render: ({ type, index }) => <Text id={IDUtil.getId('Type', index)}>{jobTypeMap[type] || type}</Text>,
      size: 'xsmall',
    },
    {
      property: 'created',
      header: 'Start Time (UTC)',
      dataCallback: ({ created }) => moment.utc(created).format('x'),
      render: ({ created, index }) => <Text id={IDUtil.getId('StartTime', index)}>{moment.utc(created).format('lll')}</Text>,
      size: 'small',
    },
    {
      property: 'elapsed',
      header: 'Duration',
      render: ({ elapsed, index }) => <Text id={IDUtil.getId('Duration', index)}>{(elapsed ? <Highlight search={searchText}><Duration value={elapsed} /></Highlight> : '-')}</Text>,
      size: 'xsmall',
    }, {
      property: 'accountId',
      header: 'Billing Account',
      render: ({ accountId, index }) => (
        <OptionalAnchor
          onClick={() => navigate(`/customers/${accountId.trim()}`)}
          permissions={pagePermissions.customers.view.page}
          highlight={searchText}
          id={IDUtil.getId('AccountId', index)}
          disabled={!accountId}
        >
          {customerMap[accountId?.trim()] || '-'}
        </OptionalAnchor>
      ),
      size: 'medium',
    },
    {
      property: 'runBy',
      header: 'User',
      size: 'small',
      render: ({ runBy, index }) => <Text id={IDUtil.getId('User', index)}>{runBy}</Text>,
    }, {
      property: 'id',
      header: 'Job ID',
      render: ({ id, index }) => (
        <Text id={IDUtil.getId('JobId', index)}>
          <Highlight search={searchText}>
            {id}
          </Highlight>
        </Text>
      ),
      primary: true,
    },
  ];

  const onSortColumn = (sort) => {
    const updatedFilters = produce(filters, (draft) => {
      draft.list.sort = sort;
      return draft;
    });
    setFilters({ ...updatedFilters });
    setSessionFilters(updatedFilters);
  };

  const onToastClose = () => {
    setResponse(undefined);
  };

  const renderToast = () => {
    let message = '';
    if (response) {
      message = (
        <Notification
          toast={true}
          status={response?.status || 'critical'}
          title={response.title}
          message={response.message}
          onClose={() => onToastClose()}
        />
      );
    }
    return message;
  };

  const filteredJobs = useMemo(() => {
    if (filters) {
      return filterJobs(searchText, jobsList);
    }
    return [];
  }, [filters, jobsList]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Data Processing Jobs:'
        search={[
          <CustomerSelector
            key='customerSelector'
            enableAllOption={true}
            onCustomerSelected={value => onSelectCustomer({ value })}
          />,
          <GLBMSearch
            key='searchText'
            value={searchText}
            onChange={onSearchChange}
          />,
          <FilterControl
            key='filterCntrl'
            filters={filters?.panel}
            onFilter={onFilterActivate}
            onClear={() => {
              const newFilterPanel = { ...filters.panel };
              delete newFilterPanel.type;
              delete newFilterPanel.status;
              onUpdateFilter(newFilterPanel);
            }}
            ignoreProps={['from', 'to']}
          />,
        ]}
        actions={[<Button
          kind='toolbar'
          icon={<Refresh />}
          onClick={() => {
            refreshJobsList();
          }}
          a11yTitle='Refresh Jobs List'
          id={IDUtil.getId('ListViewToolbarRefreshButton')}
          key='refreshBtn'
          label='Refresh'
          busy={jobsLoading}
        />]}
      />
      <GLBMDataSummary total={jobsList ? jobsList.length : 0} filtered={jobsLoading ? 0 : filteredJobs?.length} />
      <Box flex={true} fill={true}>
        <SearchTextContextProvider searchText={searchText}>
          <GLBMDataTable
            searchText={searchText}
            data={jobsLoading ? [] : filteredJobs?.map((el, i) => ({ ...el, index: i }))}
            columns={getColumns(searchText, customerMap)}
            loading={jobsLoading}
            total={filteredJobs?.length}
            sort={filters?.list?.sort}
            onSort={newSort => onSortColumn(newSort)}
            primaryKey='id'
          />
        </SearchTextContextProvider>

        {filterActive && (
          <JobsFilter
            onClose={onFilterDeactivate}
            filter={filters?.panel}
            onChange={onUpdateFilter}
          />
        )}
      </Box>
      {renderToast()}
    </Main>
  );
};

JobsListPage.propTypes = {
  jobs: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    filters: PropTypes.object,
    items: PropTypes.array,
  }),
  customer: PropTypes.array,
  setSessionFilters: PropTypes.func,
};

const mapStateToProps = store => ({
  usage: store.usage.list,
  jobs: store.job.list,
  customer: store.customer.list,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setSessionFilters: updateFiltersAndSession,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobsListPage);
