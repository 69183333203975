// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import {
  Box, Button, Stack, Text,
} from 'grommet';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';

import GLBMTooltip from '../shared/component/GLBMTooltip';

function SidebarButton({
  to, label, badge = undefined, badgeTooltip = undefined, id = ''
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [end, setEnd] = useState(0);

  const isActive = useMemo(() => location.pathname.includes(to), [location.pathname]);

  useEffect(() => {
    if (badge !== undefined) {
      setEnd(badge);
    }
  }, [badge]);

  return (
    <Stack
      guidingChild='first'
      interactiveChild='first'
      alignSelf='stretch'
      anchor='right'
    >
      <GLBMTooltip content={badgeTooltip} dropProps={{ align: { left: 'right' } }}>
        <Button
          plain={true}
          style={{
            height: '48px',
            minHeight: '48px',
            width: '100%',
          }}
          align='start'
          hoverIndicator={true}
          onClick={() => navigate(to)}
          label={(
            <Text
              margin={{ horizontal: 'medium' }}
              size='large'
              weight='normal'
              id={id}
            >
              {label}
            </Text>
        )}
          active={isActive}
        />
      </GLBMTooltip>
      {badge !== undefined
        && (
          <Box>
            <CountUp
              end={end}
              duration={0.5}
              preserveValue={true}
              separator=','
            >
              {({ countUpRef, start }) => (
                <Text ref={countUpRef} margin='small' size='large' color='#81FCED' weight='bold' data-e2e='badge-number' />
              )}
            </CountUp>
          </Box>
        )}
    </Stack>
  );
}

SidebarButton.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  badge: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  badgeTooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default SidebarButton;
