// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useErrorToast } from '../../components/shared/hooks/useErrorToast';
import {
  executeGet
} from '../api';
import { ApiContext } from '../../AppContext';

export const useFetchActionsQuery = (options) => {
  const path = useContext(ApiContext)('administration.actions.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:FETCH-ACTIONS'],
    queryFn: () => executeGet(path)
  }, options), 'Fetch Actions Error');
};
