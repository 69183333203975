// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

/* eslint-disable unused-imports/no-unused-imports */

import { toRelativeUrl } from '@okta/okta-auth-js';
import { template } from 'lodash';
import get from 'lodash/get';
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';

import {
  Navigate,
  Route,
  Routes, useLocation, useNavigate,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
import CurrencyUtils from 'i18n/CurrencyUtil';
import {
  Box, Button, Sidebar, Text,
} from 'grommet';
import { StatusIcon } from '../shared/component/StatusIcon';
import loadSentry from '../../sentry';
import { AlertSidebarButton } from '../alerts/AlertSidebarButton';
import AlertsListPage from '../alerts/AlertsListPage';
import AuditListPage from '../audit/AuditListPage';
import CompanyPage from '../companies/CompanyPage';
import CustomersPage from '../customers/CustomersPage';
import AnalyticsPage from '../analytics/AnalyticsPage';
import JobsListPage from '../jobs/JobsListPage';
import { JobsSidebarButton } from '../jobs/JobsSidebarButton';
import ServiceMeterListPage from '../serviceMeters/ServiceMeterListPage';
import {
  pagePermissions,
} from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import Loader from '../shared/loader';
import UsersPage from '../users/UsersPage';
import AccessRequests from '../accessRequests/AccessRequests';
import AuthActions from '../actions/AuthActions';
import AdministrationPage from '../administration/AdministrationPage';
import PaygAdministrationPage from '../paygAdministration/paygAdministration';
import PartnerPage from '../partners/PartnerPage';
import ServiceMappingsListPage from '../serviceMappings/ServiceMappingsListPage';
import RequestRolePage from '../requestRole/RequestRolePage';
import UsageFilePage from '../usage/UsageFilePage';
import UserStore from '../stores/UserStore';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import jsVersion from '../../version';

// -----------------------------------------------------------------------
// DO NOT REMOVE: These needs to be imported so they load + start timers:
import UserSession from '../session/UserSession';
import ServiceTypeService from '../services/ServiceTypeService';
// -----------------------------------------------------------------------

import MarketingHeader from '../headers/MarketingHeader';
import MarketingFooter from '../headers/MarketingFooter';
import SessionModal from '../session/SessionModal';

import SidebarHeader from './SidebarHeader';
import SidebarFooter from './SidebarFooter';
import MainNavigation from './SidebarNavigation';
import SidebarButton from './SidebarButton';
import Toast from '../shared/component/Toast';
import { isAssignedRole } from '../shared/constants/UserType';
import UserTypeStore from '../stores/UserTypeStore';
import DemoDataGeneration from '../administration/DemoDataGeneration/DemoDataGeneration';
import { ApiContext } from '../../AppContext';
import { useApiDefsQuery } from '../../core';

function MainPage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [me, setMe] = useState(UserStore.getUser());
  const [toastMessage, setToastMessage] = useState(undefined);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [isSentryLoaded, setIsSentryLoaded] = useState(false);
  const UserType = UserTypeStore.getTypesEnum();
  const { hasPermissions } = usePermissionChecker();

  const {
    isInitialLoading: isApiDefsInitialLoading,
    isSuccess: isApiDefsSuccess,
    isError: isApiDefsError,
    data: apiDefsData,
    apiCallback,
  } = useApiDefsQuery();

  useEffect(() => {
    if (isApiDefsSuccess) {
      // this is in preparation so these services can make endpoint calls w/o hardcoded paths:
      UserSession.setApiDefs(apiDefsData);
      ServiceTypeService.setApiDefs(apiDefsData);
    }
  }, [isApiDefsSuccess, apiDefsData]);

  useEffect(() => {
    if (!isSentryLoaded && apiDefsData) {
      loadSentry(apiDefsData['sentry.event.create.path']);
      setIsSentryLoaded(true);
    }
  }, [isSentryLoaded, apiDefsData]);

  const _onUserSessionChange = () => {
    setIsTimedOut(UserSession.showModal);
  };

  useEffect(() => {
    if (isApiDefsSuccess) {
      setLoading(true);
      UserSession.access().then((info) => {
        if (isAssignedRole(info?.authorities?.[0]?.role)) {
          UserSession.current().then((results) => {
            if (typeof Storage !== 'undefined') {
              sessionStorage.setItem('lastTokenRefresh', new Date().toISOString());
              const userCopy = JSON.parse(JSON.stringify(results));
              sessionStorage.setItem('user', JSON.stringify(userCopy));
              UserStore.setUser(userCopy);
              UserStore.configureSentry(userCopy);
              setMe(userCopy);
              setLoading(false);
            }
          }, (error) => {
            // eslint-disable-next-line no-console
            console.log('AuthActions.current.error', error);
          });
        } else {
          setMe(UserStore.getUser());
          setLoading(false);
        }
      }, (error) => {
        // eslint-disable-next-line no-console
        console.log('AuthActions.status.error', error);
      });
      UserSession.addChangeListener(_onUserSessionChange);
    }
  }, [isApiDefsSuccess]);

  const _onToastClose = () => {
    setToastMessage(undefined);
  };

  function _renderToast() {
    if (toastMessage) {
      return (
        <Toast open={toastMessage} onClose={() => _onToastClose()}>
          <Box direction='row' gap='small'>
            <StatusIcon value={toastMessage.status} />
            <span>{toastMessage.text}</span>
          </Box>
        </Toast>
      );
    }
    return null;
  }

  const _renderMenuItems = useMemo(() => {
    const menuCustomers = (<SidebarButton to='customers' key='customers' id='BillingConfiguration' label='Billing Configuration' />);
    const menuCompanies = (<SidebarButton to='companies' key='companies' id='Companies' label='Companies' />);
    const menuPaygAdministration = (<SidebarButton to='paygAdministration' key='paygAdministration' id='PAYGAdministration' label='PAYG Administration' />);
    const menuUsage = (<SidebarButton to='usage' key='usage' id='MeteringFiles' label='Metering Files' />);
    const menuAnalytics = (<SidebarButton to='analytics' key='analytics' id='Analytics' label='Analytics' />);
    const menuAlerts = (<AlertSidebarButton key='alerts' />);
    const menuUsers = (<SidebarButton to='users' key='users' id='Users' label='Users' />);
    const menuJobs = (<JobsSidebarButton key='jobs' />);
    const menuAdministration = (<SidebarButton to='administration' key='administration' id='Administration' label='Administration' />);
    const menuPartners = (<SidebarButton to='partners' key='partners' id='Partners' label='Partners' />);
    const menuAudit = (<SidebarButton to='audit' key='audit' id='AuditLogs' label='Audit Logs' />);
    const menuServiceMappings = (<SidebarButton to='servicemappings' key='servicemappings' id='ServiceMappings' label='Service Mappings' />);
    const menuServiceMeters = (<SidebarButton to='servicemeters' key='servicemeters' id='ServiceMeters' label='Service Meters' />);
    const menuAccessRequests = (<SidebarButton to='/accessrequests' key='accessrequests' id='AccessRequests' label='Access Requests' />);

    const menuItems = [];
    if (hasPermissions(pagePermissions.analytics.page)) { menuItems.push(menuAnalytics); }
    if (hasPermissions(pagePermissions.customers.page)) { menuItems.push(menuCustomers); }
    if (hasPermissions(pagePermissions.companies.page)) { menuItems.push(menuCompanies); }
    if (hasPermissions(pagePermissions.payg.page)) { menuItems.push(menuPaygAdministration); }
    if (hasPermissions(pagePermissions.serviceMappings.page)) { menuItems.push(menuServiceMappings); }
    if (hasPermissions(pagePermissions.serviceMeters.page)) { menuItems.push(menuServiceMeters); }
    if (hasPermissions(pagePermissions.alerts.page)) { menuItems.push(menuAlerts); }
    if (hasPermissions(pagePermissions.jobs.page)) { menuItems.push(menuJobs); }
    if (hasPermissions(pagePermissions.usage.page)) { menuItems.push(menuUsage); }
    if (hasPermissions(pagePermissions.users.page)) { menuItems.push(menuUsers); }
    if (hasPermissions(pagePermissions.accessRequests.page)) { menuItems.push(menuAccessRequests); }
    if (hasPermissions(pagePermissions.partners.page)) { menuItems.push(menuPartners); }
    if (hasPermissions(pagePermissions.administration.page)) { menuItems.push(menuAdministration); }
    if (hasPermissions(pagePermissions.audit.page)) { menuItems.push(menuAudit); }
    return menuItems;
  }, [hasPermissions]);

  const _renderRoutes = useMemo(() => {
    const routes = [];

    const routeCustomer = (<Route key='customers' path='customers/*' element={<CustomersPage />} />);
    const routeCompany = (<Route key='companies' path='companies/*' element={<CompanyPage />} />);
    const routePaygAdministration = (<Route key='paygAdministration/*' path='paygAdministration/*' element={<PaygAdministrationPage navigate={navigate} />} />);
    const routeAnalytics = (<Route key='analytics' path='analytics' element={<AnalyticsPage />} />);
    const routeAnalyticsAlt = (<Route key='analyticsAlt' path='analytics/:customerId/:serviceType/:tab' element={<AnalyticsPage />} />);
    const routeAlerts = (<Route key='alerts' path='alerts' element={<AlertsListPage navigate={navigate} />} />);
    const routeJobs = (<Route key='jobs' path='jobs' element={<JobsListPage navigate={navigate} />} />);
    const routeUsers = (<Route key='users' path='users/*' element={<UsersPage />} />);
    const routeAccessRequest = (<Route key='accessrequests' path='accessrequests' element={<AccessRequests navigate={navigate} />} />);
    const routeUsage = (<Route key='usage' path='usage' element={<UsageFilePage />} />);
    const routeAdministration = (<Route key='administration' path='administration' element={<AdministrationPage />} />);
    const routeMockDataEdit = (<Route key='administration' path='administration/mock/new' element={<DemoDataGeneration />} />);
    const routeMockDataCopy = (<Route key='administration' path='administration/mock/:id/copy' element={<DemoDataGeneration />} />);
    const routeMockDataGeneration = (<Route key='administration' path='administration/mock/:id' element={<DemoDataGeneration />} />);
    const routePartners = (<Route key='partners' path='partners/*' element={<PartnerPage />} />);
    const routeAudit = (<Route key='audit' path='audit' element={<AuditListPage />} />);
    const routeServiceMappings = (<Route key='servicemappings' path='servicemappings' element={<ServiceMappingsListPage />} />);
    const routeRequestRole = (<Route key='requestrole' path='requestrole' element={<RequestRolePage />} />);
    const analyticsRedirect = (<Route key='analyticsRedirect' path='*' element={<Navigate to='analytics' replace={true} />} />);
    const routeServiceMeters = (<Route key='servicemeters' path='servicemeters' element={<ServiceMeterListPage />} />);
    const requestRoleRedirect = (<Route key='requestroleRedirect' path='*' element={<Navigate to='requestrole' replace={true} />} />);

    if (me) {
      if (hasPermissions(pagePermissions.customers.page)) { routes.push(routeCustomer); }
      if (hasPermissions(pagePermissions.companies.page)) { routes.push(routeCompany); }
      if (hasPermissions(pagePermissions.payg.page)) { routes.push(routePaygAdministration); }
      if (hasPermissions(pagePermissions.analytics.page)) { routes.push(routeAnalytics); routes.push(routeAnalyticsAlt); }
      if (hasPermissions(pagePermissions.serviceMappings.page)) { routes.push(routeServiceMappings); }
      if (hasPermissions(pagePermissions.alerts.page)) { routes.push(routeAlerts); }
      if (hasPermissions(pagePermissions.jobs.page)) { routes.push(routeJobs); }
      if (hasPermissions(pagePermissions.users.page)) { routes.push(routeUsers); }
      if (hasPermissions(pagePermissions.accessRequests.page)) { routes.push(routeAccessRequest); }
      if (hasPermissions(pagePermissions.usage.page)) { routes.push(routeUsage); }
      if (hasPermissions(pagePermissions.administration.mock.page)) { routes.push(routeMockDataGeneration); }
      if (hasPermissions(pagePermissions.administration.mock.page)) { routes.push(routeMockDataEdit); }
      if (hasPermissions(pagePermissions.administration.mock.page)) { routes.push(routeMockDataCopy); }
      if (hasPermissions(pagePermissions.administration.page)) { routes.push(routeAdministration); }
      if (hasPermissions(pagePermissions.partners.page)) { routes.push(routePartners); }
      if (hasPermissions(pagePermissions.audit.page)) { routes.push(routeAudit); }
      routes.push(routeRequestRole);
      if (hasPermissions(pagePermissions.serviceMeters.page)) { routes.push(routeServiceMeters); }

      // always at the end:
      if (hasPermissions(pagePermissions.customers.page)) {
        routes.push(analyticsRedirect);
      } else {
        routes.push(requestRoleRedirect);
      }
    }

    return routes;
  }, [me, hasPermissions, navigate]);

  return (
    <Box fill={true}>
      <SessionModal
        show={isTimedOut}
        timeoutLogout={() => UserSession.timeoutLogout()}
        logout={() => UserSession.endSession()}
        continue={() => {
          UserSession.continueSession();
          setIsTimedOut(false);
        }}
      />
      <MarketingHeader />
      <Box fill={true}>
        {(isApiDefsError) && (
        <Box direction='column' align='center' gap='small' justify='center' fill={true}>
          <Text>Error occured during fetching routes. Refresh page or try again later.</Text>
          <Button
            label='Refresh'
            key='refreshBtn'
            primary={true}
            // eslint-disable-next-line no-restricted-globals
            onClick={() => location.reload()}
          />
        </Box>
        )}
        {(isApiDefsInitialLoading)
          && (
            <Box direction='row' align='center' gap='small' justify='center' fill={true}>
              <Loader text='Loading. Please wait ...' />
            </Box>
          )}
        {(isApiDefsSuccess)
          && (
            <ApiContext.Provider value={apiCallback}>
              <Box fill={true} direction='row'>
                <Box flex={false} background='neutral-1'>
                  <Box direction='row' height={{ min: '100%' }}>
                    <Sidebar
                      responsive={true}
                      background='#425563'
                      header={<SidebarHeader />}
                      footer={<SidebarFooter me={me} />}
                      pad='none'
                      gap='none'
                    >
                      <MainNavigation items={_renderMenuItems} />
                    </Sidebar>
                  </Box>
                </Box>
                <Box fill='horizontal'>
                  <Box
                    overflow='auto'
                    flex={true}
                  >
                    <Sentry.ErrorBoundary fallback='An error has occured'>
                      <Routes>
                        {_renderRoutes}
                      </Routes>
                    </Sentry.ErrorBoundary>
                  </Box>
                </Box>
                {_renderToast()}
              </Box>
            </ApiContext.Provider>
          )}
      </Box>
      <MarketingFooter />
    </Box>
  );
}

export default MainPage;
