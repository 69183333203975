// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';

import {
  Box, Button, CheckBoxGroup, Footer, Paragraph,
} from 'grommet';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateRange from '../shared/component/DateRange';
import GLBMLayer from '../shared/component/GLBMLayer';
import TypeLabel from '../shared/component/TypeLabel';
import IDUtil from '../shared/util/IDUtil';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import { StatusIcon } from '../shared/component/StatusIcon';
import StatusLabel from '../shared/component/StatusLabel';

class JobsFilter extends Component {
  constructor(props) {
    super(props);

    this._onSubmit = this._onSubmit.bind(this);
    this._setFromDate = this._setFromDate.bind(this);
    this._setToDate = this._setToDate.bind(this);
    this._onClear = this._onClear.bind(this);

    const { filter } = props;

    this.state = {
      filter: {
        customerId: filter.customerId,
        from: filter.from,
        to: filter.to,
        type: (Array.isArray(filter.type)) ? [].concat(filter.type) : undefined,
        status: (Array.isArray(filter.status)) ? [].concat(filter.status) : undefined,
      },
    };
  }

  _onChangeOption(name, event) {
    this.name = name;
    const filter = { ...this.state.filter };
    if (event.value.length === 0 || event.value[event.value.length - 1] === 'all') {
      delete filter[name];
    } else {
      filter[name] = event.value.filter(el => el !== 'all');
    }
    this.setState({ filter });
  }

  _setFromDate(value) {
    this.state.filter.from = value;
    this.setState({ filter: this.state.filter });
  }

  _setToDate(value) {
    this.state.filter.to = value;
    this.setState({ filter: this.state.filter });
  }

  _onSubmit() {
    if (this.state.filter.from && this.state.filter.to) {
      this.props.onChange(this.state.filter);
    }
  }

  _renderDateRangeWarning(filter) {
    const a = moment(filter.from);
    const b = moment(filter.to);
    const difference = a.diff(b, 'days');
    if (Math.abs(difference) >= 7) {
      return (
        <Box direction='row'>
          <StatusIcon value='warning' size='medium' />
          <Box margin={{ left: 'small' }}>
            <Paragraph style={{ marginTop: '0px' }}>
              The selected date range is greater than 7 days. This may cause delay or failure to return results. Consider selecting a smaller time period
            </Paragraph>
          </Box>
        </Box>
      );
    }
  }

  _renderStatusOptions(filter) {
    return (
      <CheckBoxGroup
        labelKey='label'
        valueKey='value'
        id={IDUtil.getId('StatusChkGroup')}
        value={filter.status ? filter.status : ['all']}
        options={[
          { label: <TypeLabel value='all' label='All' />, value: 'all', id: IDUtil.getId('StatusAll') },
          { label: <StatusLabel value='unknown' label='Processing' />, value: 'processing', id: IDUtil.getId('StatusProcessing') },
          { label: <StatusLabel value='ok' label='Succeeded' />, value: 'succeeded', id: IDUtil.getId('StatusSucceeded') },
          { label: <StatusLabel value='critical' label='Failed' />, value: 'failed', id: IDUtil.getId('StatusFailed') },
          { label: <StatusLabel value='critical' label='Terminated' />, value: 'terminated', id: IDUtil.getId('StatusTerminated') },
          { label: <StatusLabel value='critical' label='Aborted' />, value: 'aborted', id: IDUtil.getId('StatusAborted') },
        ]}
        onChange={this._onChangeOption.bind(this, 'status')}
      />
    );
  }

  _renderTypeOptions(filter) {
    return (
      <CheckBoxGroup
        labelKey='label'
        valueKey='value'
        value={filter.type ? filter.type : ['all']}
        id={IDUtil.getId('TypeChkGroup')}
        options={[
          { label: <TypeLabel value='all' label='All' />, value: 'all', id: IDUtil.getId('TypeAll') },
          { label: <TypeLabel value='charge' label='Charging' />, value: 'charge', id: IDUtil.getId('TypeCharging') },
          { label: <TypeLabel value='fill' label='Filling' />, value: 'fill', id: IDUtil.getId('TypeFilling') },
          { label: <TypeLabel value='load' label='Loading' />, value: 'load', id: IDUtil.getId('TypeLoading') },
          { label: <TypeLabel value='migrate' label='Migrating' />, value: 'migrate', id: IDUtil.getId('TypeMigrating') },
          { label: <TypeLabel value='other' label='Other' />, value: 'other', id: IDUtil.getId('TypeOther') },
          { label: <TypeLabel value='sync' label='Syncing' />, value: 'sync', id: IDUtil.getId('TypeSyncing') },
          { label: <TypeLabel value='transform' label='Transforming' />, value: 'transform', id: IDUtil.getId('TypeTransforming') },
        ]}
        onChange={this._onChangeOption.bind(this, 'type')}
      />
    );
  }

  _onClear() {
    const filter = { ...this.state.filter };
    delete filter.type;
    delete filter.status;
    this.setState({ filter });
  }

  render() {
    const { filter } = this.state;
    const { onClose } = this.props;

    return (
      <GLBMLayer
        position='right'
        full='vertical'
        flush={true}
        closer={true}
        overlayClose={false}
        onClose={onClose}
        style={{ minWidth: '520px' }}
        title='Filter Data Processing Jobs'
      >
        <Box flex={true} overflow='auto'>
          <GLBMFilterPropertyBox label='Date Range (UTC)' contentProps={{ pad: { horizontal: 'small' } }} required={true}>
            <DateRange filter={this.state.filter} setFromDate={this._setFromDate} setToDate={this._setToDate} />
            {this._renderDateRangeWarning(filter)}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Status'>
            {this._renderStatusOptions(filter)}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Type'>
            {this._renderTypeOptions(filter)}
          </GLBMFilterPropertyBox>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='between'>
            <Box justify='start' gap='small' direction='row'>
              <Button
                label='Apply'
                type='button'
                id={IDUtil.getId('ApplyBtn')}
                primary={true}
                onClick={this._onSubmit}
              />
              <Button
                label='Cancel'
                type='button'
                id={IDUtil.getId('CancelBtn')}
                secondary={true}
                onClick={onClose}
              />
            </Box>
            <Button
              label='Clear Filters'
              id={IDUtil.getId('ResetBtn')}
              onClick={this._onClear}
            />
          </Footer>
        </Box>
      </GLBMLayer>
    );
  }
}

JobsFilter.propTypes = {
  index: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

JobsFilter.defaultProps = {
  onClose: null,
  onChange: null,
};

export default JobsFilter;
