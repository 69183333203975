// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import { Box, Button, Footer } from 'grommet';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { uploadFile } from '../../../core';

const ImportFileAction = ({
  action,
  onExecute,
  onClose,
}) => {
  const [selectedFile, setSelectedFile] = useState(false);
  const dropzoneRef = useRef();

  const onDrop = (e) => {
    if (e?.length > 0) {
      setSelectedFile(e[0]);
    } else {
      setSelectedFile(false);
    }
  };

  const onUploadImportFile = async () => onExecute(() => uploadFile(action.method, action.path, selectedFile));

  return (
    <>
      <Box flex='grow' margin='small'>
        <Box flex={false}>
          <Dropzone
            multiple={false}
            accept={{ 'text/plain': ['.csv'] }}
            className='file-upload-target'
            activeClassName='file-upload-target-active'
            rejectClassName='file-upload-target-reject'
            onDrop={onDrop}
            ref={dropzoneRef}
          >
            {({
              getRootProps,
              getInputProps,
            }) => (
              <Box
                align='center'
                pad={{ vertical: 'medium' }}
                border={{
                  style: 'dashed',
                  size: 'small',
                }}
                {...getRootProps()}
              >
                Drag a .csv file to upload here.
                <input {...getInputProps()} />
              </Box>
            )}
          </Dropzone>
          <Box pad={{ vertical: 'small' }} basis='full'>
            <Button
              fill={true}
              label='Select a file'
              onClick={() => {
                dropzoneRef.current?.open();
              }}
            />
          </Box>
          {
            selectedFile && (
              <Box flex={false}>
                {selectedFile.name}
              </Box>
            )
          }
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Upload'
              type='button'
              primary={true}
              disabled={!selectedFile}
              onClick={onUploadImportFile}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
        </Footer>
      </Box>
    </>
  );
};

ImportFileAction.propTypes = {
  action: PropTypes.object.isRequired,
  onExecute: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportFileAction;
