// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP

import React, { Component } from 'react';
import {
  Box,
  Button,
  Footer,
  Select,
} from 'grommet';
import PropTypes from 'prop-types';
import GLBMLayer from '../shared/component/GLBMLayer';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';

class ServiceMappingsFilter extends Component {
  constructor(props) {
    super(props);
    this._onSubmit = this._onSubmit.bind(this);
    this._onClear = this._onClear.bind(this);

    this.state = {
      filter: JSON.parse(JSON.stringify(props.filter)),
    };
  }

  _onSubmit() {
    const { filter } = this.state;
    this.props.onChange(filter);
  }

  _onClear() {
    this.setState({
      filter: {},
    });
  }

  _onChangeFilter(name, isMultiple, event) {
    this.name = name;
    const { filter } = this.state;
    if (!event.option.value) {
      delete filter[name];
    } else if (isMultiple) {
      filter[name] = event.value.map(value => (
        typeof value === 'object' ? value.value : value));
      if (filter[name].length === 0) {
        delete filter[name];
      }
    } else {
      filter[name] = event.option.value;
      if (filter[name].length === 0) {
        delete filter[name];
      }
    }
    this.setState({ filter });
  }

  _renderAssignmentOptions(filter) {
    const options = [{ label: 'All', value: 0 }];
    options.push({ label: 'Assigned', value: 'ASSIGNED' });
    options.push({ label: 'Unassigned', value: 'UNASSIGNED' });

    let value;
    if (filter && filter.hasOwnProperty('status') && filter.status) {
      value = filter.status;
    } else {
      value = 0;
    }

    return (
      <Select
        style={{ width: '100%' }}
        inline={true}
        multiple={false}
        value={value}
        options={options}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        onChange={this._onChangeFilter.bind(this, 'status', false)}
      />
    );
  }

  _renderServiceOptions(filter) {
    const options = [{ label: 'All', value: 0 }];
    const { serviceTypes } = this.props;
    for (let i = 0; i < serviceTypes.length; i += 1) {
      options.push({ label: serviceTypes[i], value: serviceTypes[i] });
    }

    let value;
    if (filter && filter.hasOwnProperty('service') && filter.service) {
      value = filter.service;
    } else {
      value = 0;
    }

    return (
      <Select
        style={{ width: '100%' }}
        inline={true}
        multiple={false}
        value={value}
        options={options}
        labelKey='label'
        valueKey={{ key: 'value', reduce: true }}
        onChange={this._onChangeFilter.bind(this, 'service', false)}
      />
    );
  }

  render() {
    const { filter } = this.state;
    return (
      <GLBMLayer
        position='right'
        full='vertical'
        flush={true}
        closer={false}
        onEsc={this.props.onClose}
        onClickOutside={this.props.onClose}
        onClose={this.props.onClose}
        a11yTitle='Filter Service Mappings'
        title='Filter Service Mappings'
      >
        <Box
          pad='none'
          direction='column'
          fill='vertical'
        >
          <Box flex={true}>
            <GLBMFilterPropertyBox label='Billing ID:'>
              {this._renderAssignmentOptions(filter)}
            </GLBMFilterPropertyBox>
            <GLBMFilterPropertyBox label='Service:'>
              {this._renderServiceOptions(filter)}
            </GLBMFilterPropertyBox>
          </Box>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='between'>
            <Box justify='start' gap='small' direction='row'>
              <Button
                label='Apply'
                type='button'
                primary={true}
                onClick={this._onSubmit}
              />
              <Button
                label='Cancel'
                type='button'
                secondary={true}
                onClick={this.props.onClose}
              />
            </Box>
            <Button
              label='Clear Filters'
              onClick={this._onClear}
            />
          </Footer>
        </Box>

      </GLBMLayer>
    );
  }
}

ServiceMappingsFilter.propTypes = {
  index: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

ServiceMappingsFilter.defaultProps = {
  onClose: null,
  onChange: null,
};

export default ServiceMappingsFilter;
