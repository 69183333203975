// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DataMappingListPage from './DataMappingListPage';

const DataMappingPage: React.FC = () => (
  <Routes>
    <Route key='listRoute' path='/' element={<DataMappingListPage />} />
  </Routes>
);

export default DataMappingPage;
