// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Footer,
  FormField,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import CustomerSelector from '../shared/component/CustomerSelector';

import GLBMNameValueList from '../shared/component/GLBMNameValueList';
import { useServiceMappingSaveMutate } from '../../core';

const ServiceMappingEditor = (props) => {
  const [serviceMapping, setServiceMapping] = useState(JSON.parse(JSON.stringify(props.serviceMapping)));

  const { mutate: saveServiceMapping } = useServiceMappingSaveMutate();

  const _onSubmit = () => {
    if (!serviceMapping?.billingId) {
      return;
    }

    saveServiceMapping(serviceMapping, {
      onSuccess: () => {
        props.onSubmit();
      }
    });
  };

  const _onClose = () => {
    props.onClose();
  };

  const _onChangeCustomer = (name, value) => {
    const newServiceMapping = { ...serviceMapping };
    if (!value) {
      delete newServiceMapping[name];
    } else {
      newServiceMapping[name] = value;
      if (newServiceMapping[name].length === 0) {
        delete newServiceMapping[name];
      }
    }
    setServiceMapping(newServiceMapping);
  };

  const _renderCustomerOptions = billingId => (
    <FormField label='Specify Billing ID'>
      <CustomerSelector
        initialSelection={billingId}
        style={{ width: '100%' }}
        allowNoSelection={true}
        onCustomerSelected={value => _onChangeCustomer('billingId', value)}
        plain={true}
      />
    </FormField>
  );

  const _renderDetails = mapping => (
    <GLBMNameValueList
      title='Selected Service'
      data={[
        { label: 'Service Name', value: mapping.serviceName },
        { label: 'Service Instance Id', value: mapping.id },
        { label: 'GLC Tenant Id', value: mapping.glhcTenantId },
        { label: 'GLC Tenant Name', value: mapping.customerName },
      ]}
    />
  );

  return (
    <GLBMLayer
      position='right'
      onClose={_onClose}
      onEsc={_onClose}
      onClickOutside={_onClose}
      closer={true}
      flush={true}
      overlayClose={true}
      full='vertical'
      title='Assign Billing ID'
    >
      <Box
        pad='none'
        style={{ 'width': '600px', 'minWidth': '600px' }}
        direction='column'
        flex={true}
        fill='vertical'
      >
        <Box pad='medium' flex={true}>
          <Box direction='row' align='center' pad={{ vertical: 'small' }}>
            {_renderDetails(serviceMapping)}
          </Box>
          <Box tag='section' pad={{ vertical: 'small' }} flex={false}>
            {_renderCustomerOptions(serviceMapping.billingId)}
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          <Button label='OK' primary={true} onClick={serviceMapping.billingId ? _onSubmit : undefined} />
          <Button label='Cancel' secondary={true} onClick={_onClose} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ServiceMappingEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ServiceMappingEditor;
