// (C) Copyright 2017-2024 Hewlett Packard Enterprise Development LP
import React from 'react';
import { Box, TableCell, Text } from 'grommet';
import Loader from '../loader';
import { ListPlaceholder } from '../component/ListPlaceholder';

const renderColumnTableCell = columns => columns.map(column => <TableCell key={column} scope='col' border='bottom' style={{ padding: '6px 12px' }}><Text weight='bold'>{column}</Text></TableCell>);

const renderTablePlaceholder = (loading, total, filtered) => {
  let noRows;
  if (loading) {
    noRows = (
      <Box direction='row' align='center' gap='small' justify='center' fill={true}>
        <Loader text='Loading. Please wait ...' />
      </Box>
    );
  } else if (total === 0) {
    noRows = (
      <ListPlaceholder
        emptyMessage='Nothing to show.'
        unfilteredTotal={0}
        filteredTotal={1}
      />
    );
  } else if (filtered === 0) {
    noRows = (
      <ListPlaceholder
        emptyMessage='Your filter returned zero instances. To continue, adjust it.'
        unfilteredTotal={0}
        filteredTotal={1}
      />
    );
  }

  if (noRows) {
    return (
      <Box
        fill={true}
        align='center'
        justify='center'
        direction='row'
        pad='large'
        gap='small'
        background={{ color: 'background-front', opacity: 'strong' }}
      >
        {noRows}
      </Box>
    );
  }
};

export {
  renderColumnTableCell,
  renderTablePlaceholder,
};
